/**
 * Cannot use the v9 client SDK in server side calls.
 *
 * The queries here are intended to be used as snapshot listeners
 * and response to real time data updates within the app.
 *
 * Server side queries are handled in ./firebaseAdmin.ts
 */
import { initializeApp } from 'firebase/app';
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  Unsubscribe,
  updatePassword,
} from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';
import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryConstraint,
  QuerySnapshot,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  writeBatch,
  limit,
  connectFirestoreEmulator,
  deleteField,
  where,
  orderBy,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  useQuery as UseQuery,
  UseQueryOptions,
  useInfiniteQuery as UseInfiniteQuery,
} from '@tanstack/react-query';
import {
  Address,
  AddressType,
  AddressVerificationResponse,
  FollowData,
  Message,
  PlaceDetails,
  PlacesData,
  Response,
  SendMsgProps,
  VerificationType,
} from '../types';
import {
  CancelTypeData,
  CancelType,
  CancelSource,
  AdditionalQuery,
  TypesenseSearchProps,
  GetCheckoutResponse,
} from 'types';
import {
  BackendFuctions,
  Book,
  CustomQueryProps,
  CustomQueryResponse,
  PangoCollections,
} from 'types';
import { setUserToken } from '~/lib/session';
import { cleanOrderData, serializeTypesenseOptions } from 'sdk';

import { v4 as uuid, v5 as uuidv5 } from 'uuid';

export const firebaseClientConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
};

const app = initializeApp(firebaseClientConfig);
export const db = getFirestore(app);
const functions = getFunctions(app);

// connectFunctionsEmulator(functions, 'localhost', 5001);
// connectFirestoreEmulator(db, 'localhost', 8080);

// if (Boolean(process.env.NEXT_PUBLIC_EMULATION)) {
//   connectFunctionsEmulator(functions, 'localhost', 5001);
// }

const timestamp = {
  time_added: serverTimestamp(),
  timestamp: serverTimestamp(),
};

export function firebaseAnalytics() {
  return getAnalytics(app);
}

function parseData<D>(data): D {
  return JSON.parse(JSON.stringify(data));
}

export async function fetchCollectionDocByUid<D>(
  id: string,
  collectionName: PangoCollections,
  parse: boolean = true
) {
  try {
    const collectionRef = doc(db, collectionName, id);
    const document = await getDoc(collectionRef);

    if (document.exists()) {
      if (parse) {
        return parseData<
          D & {
            id: string;
          }
        >({ ...document.data(), id } as D & { id: string });
      } else {
        return { ...document.data(), id } as D & { id: string };
      }
    }

    return null;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function fetchCollectionDocByQuery<D>(
  path: string,
  constraints: QueryConstraint[]
) {
  try {
    const collectionRef = collection(db, path);
    const document = await getDocs(query(collectionRef, ...constraints));

    if (document) {
      const doc = document.docs[0];

      return parseData<
        D & {
          id: string;
        }
      >({ ...doc.data(), id: doc.id } as D & {
        id: string;
      });
    }
  } catch (e: any) {
    throw new Error(e);
  }
}

export interface FetchCollectionDocsByQueryProps {
  path: string;
  constraints: QueryConstraint[];
  parse?: boolean;
}
export async function fetchCollectionDocsByQuery<D>({
  path,
  constraints,
  parse = false,
}: FetchCollectionDocsByQueryProps) {
  try {
    const collectionRef = collection(db, path);
    const document = await getDocs(query(collectionRef, ...constraints));

    if (document) {
      return document.docs.map((doc) => {
        {
          if (parse) {
            return parseData<
              D & {
                id: string;
                docSnap: DocumentSnapshot;
              }
            >({ ...doc.data(), id: doc.id, docSnap: doc } as D & {
              id: string;
              docSnap: DocumentSnapshot;
            });
          } else {
            return { ...doc.data(), id: doc.id, docSnap: doc } as D & {
              id: string;
              docSnap: DocumentSnapshot;
            };
          }
        }
      });
    }
  } catch (e: any) {
    throw new Error(e);
  }
}

export function createBackendFunction<D, A = void>(name: BackendFuctions) {
  return async (args: A): Promise<D> => {
    try {
      const request = httpsCallable<A, D>(functions, name);
      const { data } = await request(args);

      return data;
    } catch (e: any) {
      console.error('backend function error', e.message);
      throw new Error(e.message);
    }
  };
}

export async function backendFunction<D, A>(name: BackendFuctions, args: A) {
  try {
    const request = httpsCallable<unknown, D>(functions, name);
    const { data } = await request(args);

    return data;
  } catch (e: any) {
    console.error('backend function error', e.message);
    throw new Error(e.message);
  }
}

export interface BackendFunctionPaginationResponse<D> {
  data: D;
  hasMore: boolean;
  nextCursorId?: string;
}

export async function backendFunctionWithPagination<D, A>({
  name,
  props,
  startAfterCursorId,
}: {
  name: BackendFuctions;
  props: any;
  startAfterCursorId?: string;
}) {
  try {
    const res = await backendFunction<BackendFunctionPaginationResponse<D>, A>(
      name,
      { ...props, startAfterCursorId }
    );
    return {
      data: res.data,
      nextCursorId: res.nextCursorId,
      hasMore: res.hasMore,
    };
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function multiFetchCollectionDocsByQuery<D>({
  queries,
}: {
  queries: FetchCollectionDocsByQueryProps[];
}) {
  try {
    // use fetchCollectionDocsByQuery
    const promises = queries.map((query) =>
      fetchCollectionDocsByQuery<D>(query)
    );

    const results = await Promise.all(promises);

    return results;
  } catch (e: any) {
    throw new Error(e);
  }
}

export interface InfiniteQueryResponse<D> {
  data: D[];
  hasMore: boolean;
  nextCursor: DocumentData | undefined;
}
export async function fetchCollectionDocsByInfiniteQuery<D>({
  path,
  constraints,
  limitNum = 10,
  additionalQuery,
  startAfterCursor,
}: {
  path: string;
  constraints: QueryConstraint[];
  limitNum?: number;
  additionalQuery?: AdditionalQuery;
  startAfterCursor?: DocumentData;
}): Promise<InfiniteQueryResponse<D & { id: string }>> {
  if (startAfterCursor) {
    constraints.push(startAfter(startAfterCursor));
  }

  constraints.push(limit(limitNum));

  const collectionRef = collection(db, path);
  const snapshot = await getDocs(query(collectionRef, ...constraints));

  if (!snapshot?.docs?.length) return null;

  const data = snapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id } as D & { id: string };
  });

  if (additionalQuery && data.length) {
    const ids = data
      .map((item) => item[additionalQuery.key as keyof D] as string)
      .filter(Boolean);
    // remove duplicates
    const uniqueIds = [...new Set(ids)];
    const additionalData = await fetchDataFromIds(
      uniqueIds,
      additionalQuery.path as PangoCollections
    );

    for (const item of data) {
      const additionalItem = additionalData.find(
        (d: any) => d.id === item[additionalQuery.key as keyof D]
      ) as any;
      if (additionalItem) {
        item[additionalQuery.name as keyof D] = additionalItem;
      }
    }
  }

  const hasMore = snapshot.docs.length === limitNum;
  const nextCursor = hasMore
    ? snapshot.docs[snapshot.docs.length - 1]
    : undefined;

  return {
    data,
    nextCursor,
    hasMore,
  };
}

export async function fetchDataFromIds<D>(
  ids: string[],
  collection: PangoCollections
) {
  if (!ids) return [];

  const promises: Promise<D>[] = [];
  ids.forEach((id) => {
    promises.push(fetchCollectionDocByUid<D>(id, collection));
  });

  const promiseResponses = await Promise.allSettled(promises);
  const data: D[] = [];

  promiseResponses.forEach((promise) => {
    if (promise.status === 'fulfilled') {
      promise.value && data.push(promise.value);
    }
  });

  return data;
}

export async function fetchCollectionDocByPath<D>(
  id: string,
  path: string,
  parse: boolean = false
) {
  const docRef = doc(db, path, id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    if (parse) {
      return parseData<
        D & {
          id: string;
        }
      >({ ...docSnap.data(), id } as D & { id: string });
    } else {
      return { ...docSnap.data(), id } as D & { id: string };
    }
  }

  return null;
}

export async function fetchDocByRef<D>(
  docRef: DocumentReference<DocumentData>
) {
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return parseData<
      D & {
        id: string;
      }
    >({ ...docSnap.data(), id: docRef.id } as D & { id: string });
  }

  return null;
}

export function realtimeCollectionListener(
  path: string,
  constraints: QueryConstraint[],
  callback: (snapshot: QuerySnapshot<DocumentData>) => void,
  onError?: (err: Error) => void
): Unsubscribe {
  const q = query(collection(db, path), ...constraints);
  return onSnapshot(q, callback, onError);
}

export function realtimeDocumentListener(
  path: string,
  id: string,
  callback: (doc: DocumentSnapshot<DocumentData>) => void
): Unsubscribe {
  return onSnapshot(doc(db, path, id), callback);
}

export async function createFirestoreDoc(
  path: string,
  data: any,
  id?: string,
  merge?: boolean // Merge data from the the old doc if it exists (good if you dont know whether the doc exists),
) {
  let docRef: DocumentReference = null;
  if (id) {
    docRef = doc(db, path, id);
  } else {
    docRef = doc(collection(db, path));
  }
  await setDoc(docRef, data, merge && { merge: true });
  return docRef;
}

export async function updateFirestoreDoc(path: string, id: string, data: any) {
  const docRef = doc(db, path, id);
  await updateDoc(docRef, data);
  return docRef;
}

export async function deleteFirestoreDoc(path, id) {
  const docRef = doc(db, path, id);
  return deleteDoc(docRef);
}

export async function updateProfile(
  name: string,
  username: string,
  bio: string
) {
  const updateProfileCloudFunc = httpsCallable(
    functions,
    'users_manager-updateProfile'
  );
  return updateProfileCloudFunc({ name, username, bio });
}

export async function removeBookFromStore(book: Book) {
  const batch = writeBatch(db);

  const bookRef = doc(db, 'books', book.id);

  batch.delete(bookRef);

  return batch.commit();
}

export async function removeListingFromTitle(book: Book) {
  const docRef = doc(db, 'books', book.id);
  await updateDoc(docRef, { score: 0, isbn: '', title_id: deleteField() });
  return;
}

export async function updateAuthorImage(file: File, fileName: string) {
  const storage = getStorage();
  const authorRef = ref(storage, `author_images/${fileName}`);

  const fileSnap = await uploadBytes(authorRef, file);
  const photoURL = await getDownloadURL(fileSnap.ref);

  return photoURL;
}

export async function updateImage(uid: string, file: File) {
  const storage = getStorage();
  const userRef = doc(db, 'users', uid);
  const avatarRef = ref(storage, `profile_images/${uid}/${uuid()}`);
  const fileSnap = await uploadBytes(avatarRef, file);
  return updateDoc(userRef, {
    photo_path: fileSnap.ref.fullPath,
  });
}

export async function uploadMessageImage(uid: string, file: File) {
  try {
    const storage = getStorage();

    const storageRef = ref(storage, `message_attachments/${uid}/${uuid()}`);

    const fileSnap = await uploadBytes(storageRef, file);
    const photoURL = await getDownloadURL(fileSnap.ref);

    return photoURL;
  } catch (e: any) {
    console.log('err', e.message);
  }
}

export async function uploadImageCloudStorage(uid: string, file: File) {
  try {
    const storage = getStorage();
    const storageRef = ref(storage, `issue_images/${uid}/${file.name}`);

    const fileSnap = await uploadBytes(storageRef, file);
    const photoURL = await getDownloadURL(fileSnap.ref);

    return photoURL;
  } catch (e: any) {
    console.log('err', e.message);
  }
}

export async function sendMessageBatch(props: SendMsgProps) {
  return new Promise<void>(async (res, rej) => {
    try {
      let msgData: Message = {
        ...props,
        book: props.book ? props.book : false,
        order: props.order ? cleanOrderData(props.order) : false,
        timestamp: Date.now(),
        serverTimestamp: serverTimestamp(),
        read: false,
        imageUrl: props.imageUrl || null,
      };
      // create a new batch
      const batch = writeBatch(db);
      // create refs
      const msgRef = doc(collection(db, `messages/${props.chatId}/messages`));
      const myRef = doc(
        db,
        `users/${props.fromId}/recent_messages`,
        props.toId
      );
      const theirRef = doc(
        db,
        `users/${props.toId}/recent_messages`,
        props.fromId
      );
      // set the refs
      console.log('msgData', msgData);
      batch.set(msgRef, msgData);
      batch.set(myRef, { ...msgData, read: true });
      batch.set(theirRef, msgData);
      // commit the batch
      await batch.commit();
      res();
    } catch (e) {
      // console.log(e);
      rej(e);
    }
    return;
  });
}

export function addWishlistItemToFirestore(item: Book, uid: string) {
  return new Promise<void>(async (res, rej) => {
    try {
      await setDoc(doc(db, `wishlist/${uid}/wishlist`, item.id), {
        ...item,
        docSnap: null,
        book_id: item.id,
        time_added: serverTimestamp(),
        timestamp: serverTimestamp(), // for backwards compatibility (remove after time_added has been used on the web and app for a while)
      });
      res();
    } catch (e) {
      rej(e);
    }
  });
}

export async function addCartItemToFirestore(item: Book, uid: string) {
  // TODO -
  // We can just return the firestore functions since they are promises themselves.
  // To return a promise explicitly is redundant.  Eg the different between this function and removeCartItemFromFirestore
  try {
    return await setDoc(doc(db, `cart/${uid}/cart`, item.id), {
      ...item,
      docSnap: null,
      time_added: serverTimestamp(),
    });
  } catch (e: any) {
    throw new Error(e);
  }
}

export function removeWishlistItemFromFirestore(item: Book, uid: string) {
  return new Promise<void>(async (res, rej) => {
    try {
      await deleteDoc(doc(db, `wishlist/${uid}/wishlist`, item.id));
      res();
    } catch (e) {
      rej(e);
    }
  });
}

export function removeCartItemFromFirestore(item: Book, uid: string) {
  return new Promise<void>(async (res, rej) => {
    try {
      await deleteDoc(doc(db, `cart/${uid}/cart`, item.id));
      res();
    } catch (e) {
      rej(e);
    }
  });
}

export function removeFollowedUserFromFirestore(sellerId: string, uid: string) {
  return new Promise<void>(async (res, rej) => {
    try {
      const batch = writeBatch(db);
      const myUserRef = doc(db, `users/${uid}/following`, sellerId);
      const sellerUserRef = doc(db, `users/${sellerId}/followers`, uid);

      batch.delete(myUserRef);
      batch.delete(sellerUserRef);
      await batch.commit();
      res();
    } catch (e) {
      rej(e);
    }
  });
}

export function addFollowedUserToFirestore(sellerId: string, uid: string) {
  return new Promise<void>(async (res, rej) => {
    try {
      const batch = writeBatch(db);
      const myUserRef = doc(db, `users/${uid}/following`, sellerId);
      const sellerUserRef = doc(db, `users/${sellerId}/followers`, uid);

      batch.set(myUserRef, timestamp);
      batch.set(sellerUserRef, timestamp);
      await batch.commit();
      res();
    } catch (e) {
      rej(e);
    }
  });
}

export async function saveAddress(address: Address, address_type: AddressType) {
  // TODO: this is considerably slower than a client db update. Replace w/ a client firestore call +
  // rules to allow user to change their own address
  const saveAddressCloudFunc = httpsCallable(functions, 'shipping-saveAddress');
  return saveAddressCloudFunc({ address, address_type });
}

export async function verifyAddress(address: Address) {
  const verifyAddressCloudFunc = httpsCallable(
    functions,
    'shipping-verifyShippingAddress'
  );
  return new Promise<AddressVerificationResponse>(async (res, rej) => {
    try {
      const response = await verifyAddressCloudFunc({ address });
      res(response.data as AddressVerificationResponse);
    } catch (e) {
      rej(e);
    }
  });
}

export async function getAddressSuggestions(address: string) {
  const addressAutocomplete = httpsCallable(
    functions,
    'shipping-addressAutocomplete'
  );
  return new Promise<any>(async (res, rej) => {
    try {
      const response = await addressAutocomplete({ text: address });
      const data = response.data as PlacesData;
      // *FIXME* handle different PlacesStatus codes
      const predictions = data.predictions;
      if (predictions) {
        res(predictions);
      } else {
        rej({ code: 'not-found', message: 'No predictions' });
      }
    } catch (e) {
      rej(e);
    }
    return;
  });
}

export async function connectPayPalAccount({ auth_code, uid, code }) {
  const connectPayPalAccountCloudFunc = httpsCallable(
    functions,
    'paypal-connectPaypalAccount'
  );
  return new Promise<{ success: boolean }>(async (res, rej) => {
    try {
      const response: { data } = await connectPayPalAccountCloudFunc({
        auth_code,
        uid,
        code,
      });
      res(response.data);
    } catch (e) {
      rej(e);
    }
  });
}

export async function getPlaceFromId(place_id: string) {
  const getPlaceFromId = httpsCallable(functions, 'shipping-getPlaceFromId');
  return new Promise<PlaceDetails>(async (res, rej) => {
    try {
      const response = await getPlaceFromId({ place_id });
      const data = response.data as any;
      // *FIXME* handle different PlacesStatus codes
      const address = data.address as PlaceDetails;
      if (address) {
        res(address);
      } else {
        rej({ code: 'not-found', message: 'No place found' });
      }
    } catch (e) {
      rej(e);
    }
    return;
  });
}

export function saveNewPaymentMethod(token: string) {
  return new Promise(async (res, rej) => {
    try {
      const savePaymentMethod = httpsCallable(
        functions,
        'payment-savePaymentMethod'
      );
      const response = (await savePaymentMethod({ token })) as any;
      res(response.data.paymentMethod);
    } catch (e: any) {
      rej(e);
    }
    return;
  });
}

export function removePaymentMethod(token: string) {
  return new Promise(async (res, rej) => {
    try {
      const removePaymentMethodCloudFunc = httpsCallable(
        functions,
        'payment-removePaymentMethod'
      );
      const response = (await removePaymentMethodCloudFunc({ token })) as any;
      res(response.data);
    } catch (e: any) {
      rej(e);
    }
  });
}

export function submitNewAccountEmail(new_email: string) {
  return new Promise(async (res, rej) => {
    try {
      const submitNewAccountEmailCloudFunc = httpsCallable(
        functions,
        'email_manager-submitNewAccountEmail'
      );
      const response = (await submitNewAccountEmailCloudFunc({
        new_email,
      })) as any;
      res(response.data);
    } catch (e: any) {
      rej(e);
    }
  });
}

export function resendEmailVerification(type: VerificationType) {
  return new Promise(async (res, rej) => {
    try {
      const resendEmailVerificationCloudFunc = httpsCallable(
        functions,
        'email_manager-resendEmailVerification'
      );
      const response = (await resendEmailVerificationCloudFunc({
        type,
      })) as any;
      res(response.data);
    } catch (e: any) {
      rej(e);
    }
  });
}

export function verifyNewAccountEmail(email_token: string) {
  return new Promise(async (res, rej) => {
    try {
      const verifyNewAccountEmailCloudFunc = httpsCallable(
        functions,
        'email_manager-verifyNewAccountEmail'
      );
      const response = (await verifyNewAccountEmailCloudFunc({
        email_token,
      })) as any;
      res(response.data);
    } catch (e: any) {
      rej(e);
    }
  });
}

export async function refreshToken() {
  const auth = getAuth();
  const token = await auth?.currentUser?.getIdToken(true);
  if (token) {
    await setUserToken(token);
    return true;
  }
  return false;
}

export function changePassword(current_pw: string, new_pw: string) {
  return new Promise(async (res, rej) => {
    try {
      // reauthenticate
      const auth = getAuth();
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, current_pw);
      await reauthenticateWithCredential(user, credential);
      // change password
      await updatePassword(user, new_pw);
      res({ success: true });
    } catch (e: any) {
      if (e.code?.startsWith('auth/')) {
        rej(Error(e.code.replace('auth/', '')));
      } else {
        rej(e);
      }
    }
  });
}

export function getBookFromSold(book_id: string) {
  return new Promise(async (res, rej) => {
    try {
      const getBookFromSoldCloudFunc = httpsCallable(
        functions,
        'books-getBookFromSold'
      );
      const response = (await getBookFromSoldCloudFunc({ book_id })) as any;
      res(response.data);
    } catch (e: any) {
      rej(e);
    }
  });
}

export function clonePage(path: string, slug: string, userName: string) {
  return new Promise(async (res, rej) => {
    try {
      const clonePageCloudFunc = httpsCallable(functions, 'admin-clonePage');
      const response = (await clonePageCloudFunc({
        path,
        slug,
        userName,
      })) as any;
      res(response.data);
    } catch (e: any) {
      rej(e);
    }
  });
}

export function getOrderCancelType(order_id: string): Promise<CancelTypeData> {
  return new Promise<CancelTypeData>(async (res, rej) => {
    try {
      const getCancelTypeCloudFunc = httpsCallable(
        functions,
        'orders-getCancelType'
      );
      const response = (await getCancelTypeCloudFunc({ order_id })) as any;
      res(response.data as CancelTypeData);
    } catch (e: any) {
      rej(e);
    }
  });
}

export function cancelOrder(
  source: CancelSource,
  type: CancelType,
  order_id: string,
  note: string | undefined = undefined
): Promise<Response> {
  return new Promise(async (res, rej) => {
    try {
      const cancelOrderCloudFunc = httpsCallable(
        functions,
        'orders-cancelOrder'
      );
      const response = (await cancelOrderCloudFunc({
        source,
        type,
        order_id,
        note,
      })) as any;
      res(response.data as Response);
    } catch (e: any) {
      rej(e);
    }
  });
}

export function reportIssue(
  order_id: string,
  description: string,
  type: 'return' | 'other_issue',
  photo_urls?: any[]
): Promise<Response> {
  return new Promise(async (res, rej) => {
    try {
      const reportIssueCloudFunc = httpsCallable(
        functions,
        'orders-reportIssue'
      );
      const response = (await reportIssueCloudFunc({
        order_id,
        description,
        type,
        photo_urls,
      })) as any;
      res(response.data as Response);
    } catch (e: any) {
      rej(e);
    }
  });
}

export function sellerReview(
  order_id: string,
  rating: number,
  review: string | undefined,
  tags: string[] | undefined
): Promise<Response> {
  return new Promise<Response>(async (res, rej) => {
    try {
      const sellerReviewCloudFunc = httpsCallable(
        functions,
        'orders-sellerReview'
      );
      const response = (await sellerReviewCloudFunc({
        order_id,
        rating,
        review,
        tags,
      })) as any;
      res(response.data as Response);
    } catch (e: any) {
      rej(e);
    }
  });
}

export async function processPangoPayment(input) {
  const processPangoPaymentCloudFunc = httpsCallable(
    functions,
    'payment-onlyPangobucksPurchase'
  );
  const response = await processPangoPaymentCloudFunc(input);
  return response.data as { success: boolean };
}

export async function getCheckout(input): Promise<GetCheckoutResponse> {
  const getCheckoutCloudFunc = httpsCallable(functions, 'checkout-getCheckout');
  const response = await getCheckoutCloudFunc(input);
  return response.data as GetCheckoutResponse;
}

export async function redeemPromo(input) {
  const redeemPromoCloudFunc = httpsCallable(
    functions,
    'promotions-redeemPromo'
  );
  const response = await redeemPromoCloudFunc(input);
  return response.data as { success: boolean; amount: number };
}

export async function getFollowData(user_id: string) {
  const getFollowDataCloudFunc = httpsCallable(
    functions,
    'users-getFollowStats'
  );
  const response = await getFollowDataCloudFunc({ user_id });
  return response.data as FollowData;
}

export async function holdBooksForPurchase(book_ids: string[]) {
  const holdBooksForPurchaseCloudFunc = httpsCallable(
    functions,
    'payment-holdBooksForPurchase'
  );

  await holdBooksForPurchaseCloudFunc({ book_ids });
}

export async function fetchCustomerCards() {
  const doFetch = httpsCallable(functions, 'payment-listPaymentMethods');

  const response = await doFetch();
  return response.data;
}

export async function detachPaymentMethod(data) {
  const { payment_method_id } = data;
  const payload = { payment_method_id };
  const doFetch = httpsCallable(functions, 'payment-detachPaymentMethod');

  const response = await doFetch(payload);
  return response.data;
}

export async function attachPaymentMethod(data) {
  const { payment_method_id } = data;
  const payload = { payment_method_id };
  const doFetch = httpsCallable(functions, 'payment-attachPaymentMethod');

  const response = await doFetch(payload);
  return response.data;
}

export async function getLabel(order_id: string, label_id: string) {
  const getLabelCloudFunc = httpsCallable(functions, 'shipping-getLabel');

  const response = await getLabelCloudFunc({ order_id, label_id });
  return response.data as string;
}

export async function customQuery({
  queryType,
  queryProps,
  uid,
}: CustomQueryProps) {
  const customQueryCloudFunc = httpsCallable(functions, 'curation-customQuery');
  const response = await customQueryCloudFunc({ queryType, queryProps, uid });
  return response.data as CustomQueryResponse<any>;
}

export const DEFAULT_CACHE_TIME = 60 * 1000; // 1 minute

export async function saveSearch({
  typesenseOptions,
  uid,
  type,
}: {
  typesenseOptions: TypesenseSearchProps;
  uid: string;
  type: 'add' | 'remove';
}) {
  // check whether the search is saved
  try {
    const { serializedOptions, searchOptions, defaultSearchName } =
      serializeTypesenseOptions({ typesenseOptions });
    const searchId = uuidv5(serializedOptions, uuidv5.URL) as string;
    const docRef = doc(db, `users/${uid}/saved_searches/${searchId}`);
    if (type === 'add') {
      // save the search
      await setDoc(docRef, {
        id: searchId,
        typesenseOptions: JSON.stringify(searchOptions),
        search_name: defaultSearchName,
        notify: true, // defail notify to true
        new_results: 0,
        time_saved: serverTimestamp(),
        timestamp: Date.now(),
      });
    } else {
      // remove the search
      await deleteDoc(docRef);
    }

    return { defaultSearchName };
  } catch (e) {
    // TODO add error handling
  }
}

export async function deleteSavedSearch({
  uid,
  searchId,
}: {
  uid: string;
  searchId: string;
}) {
  try {
    const docRef = doc(db, `users/${uid}/saved_searches/${searchId}`);
    await deleteDoc(docRef);
    return 'Saved Search Deleted';
  } catch (e) {
    return e;
  }
}

export async function toggleNotifySavedSearch({
  uid,
  searchId,
  notify,
}: {
  uid: string;
  searchId: string;
  notify: boolean;
}) {
  try {
    const docRef = doc(db, `users/${uid}/saved_searches/${searchId}`);
    await updateDoc(docRef, { notify });
    return `Notifications for saves search turned ${notify ? 'on' : 'off'}`;
  } catch (e) {
    return e;
  }
}

export async function updateSavedSearchResultsCount({
  uid,
  searchId,
}: {
  uid: string;
  searchId: string;
}) {
  try {
    const docRef = doc(db, `users/${uid}/saved_searches/${searchId}`);
    await updateDoc(docRef, { new_results: 0 });
    return 'New results set to 0';
  } catch (e) {
    return e;
  }
}

export async function reviewSummaryFeedback({
  titleId,
  feedback,
}: {
  titleId: string;
  feedback: string;
}) {
  try {
    const reviewSummaryFeedbackFn = httpsCallable(
      functions,
      'ab_testing-updateReviewSummaryFeedback'
    );

    const result = await reviewSummaryFeedbackFn({ titleId, feedback });
    return result.data;
  } catch (e) {
    console.error('Error submitting feedback:', e);
    return e;
  }
}
